.swal-footer {
    text-align: center;
}

.select-filter {
    top: 210px !important;
}

.select-project {
    top: 390px !important;
}